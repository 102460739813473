.button {
  border: none;
  margin: 0.5rem;
  padding: 1rem;
  opacity: 0.8;
  font-family: Electrolize, Arial, sans-serif;
  font-size: 1.5rem;
  border-radius: var(--border-radius);
  cursor: pointer;
  transition: box-shadow 0.2s ease-in-out;
  color: var(--text-color);
  border-radius: 18px;
  --shadow-intensity: 12px;
  /* background: linear-gradient(
    145deg,
    var(--background-light),
    var(--background-dark)
  );
  box-shadow: 7px 7px 20px var(--shadow-light),
    -7px -7px 20px var(--shadow-dark); */

  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 50px;
  width: 50px; */
}

.buttonDark {
  border-radius: 18px;
  background: linear-gradient(
    315deg,
    var(--background-dark),
    var(--background-light)
  );
  box-shadow: 7px 7px 20px var(--shadow-light),
    -7px -7px 20px var(--shadow-dark);
}

.section-input {
  display: flex;
  justify-content: space-around;
}

input[type="number"] {
  width: 5rem;
  text-align: center;
  background: transparent;
  border: none;
  outline: none;
  box-shadow: none;
  color: var(--text-color);
  font-size: 1.5rem;
  font-family: Electrolize, Arial, sans-serif;
}

input[type="number"]::placeholder {
  color: var(--text-color);
  opacity: 0.5;
}

input[type="number"]:-webkit-autofill,
input[type="number"]:-webkit-autofill:hover,
input[type="number"]:-webkit-autofill:focus,
input[type="number"]:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

input[type="number"]:focus {
  background: transparent;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"]::-moz-focus-inner {
  border: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.title {
  color: var(--text-color);
  font-family: Electrolize;
  font-size: 1.25rem;
  font-weight: 200;
  margin: 0;
}
