.container {
    margin: 50px;
    box-sizing: border-box;
    /*width: calc(25% - 4px);*/
    position: relative;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
}

.container_grid_1 {
    width: calc(100%);
}

.container_grid_2 {
    width: calc(50% - 4px);
}

.container_grid_3 {
    width: calc(33.33% - 4px);
}

.container_grid_4 {
    width: calc(25% - 4px);
}

.image {
    box-sizing: border-box;
    width: 100%;
    /*height: 90%;*/
    border-radius: 7px;
    object-fit: cover;
}

.description {
    width: 100%;
    text-align: center;
    font-size: 0.8rem;
    font-family: Electrolize, sans-serif;
}