.neumorphic-divider {
  border: none;
  min-height: 1px;
  width: 100%;
  margin: 10px 0;
  background: linear-gradient(
    236deg,
    var(--background-dark),
    var(--text-color),
    var(--background-dark)
  );
  opacity: 0.3;
}

@media (prefers-color-scheme: dark) {
  .neumorphic-divider {
    background: linear-gradient(
      236deg,
      var(--background-dark),
      var(--text-color),
      var(--background-dark)
    );
  }
}
