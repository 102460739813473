.button {
  border: none;
  margin: 0.5rem;
  padding: 1rem;
  font-family: Electrolize, Arial, sans-serif;
  font-size: 1.25rem;
  font-weight: 300;
  opacity: 0.8;
  color: var(--text-color);
  cursor: pointer;
  transition: box-shadow 0.2s ease-in-out;

  border-radius: 18px;
  background: linear-gradient(
    315deg,
    var(--background-dark),
    var(--background-light)
  );
  box-shadow: 7px 7px 20px var(--shadow-light),
    -7px -7px 20px var(--shadow-dark);

  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
}

.button:disabled,
.button:disabled:hover,
.button:disabled:active {
  opacity: 0.5;
  cursor: not-allowed;
  box-shadow: 7px 7px 20px var(--shadow-light),
    -7px -7px 20px var(--shadow-dark);
  transition: none;
}

.buttonDark {
  border-radius: 18px;
  background: linear-gradient(
    315deg,
    var(--background-dark),
    var(--background-light)
  );
  box-shadow: 7px 7px 20px var(--shadow-light),
    -7px -7px 20px var(--shadow-dark);
}
