.select {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  user-select: none;
}

.option {
  list-style-type: none;
  padding: 0;
  margin: 0;
  cursor: default;
  margin: 0.3rem;
  font-size: 1.5rem;
}

.selected {
  color: var(--text-alarmed);
}
