footer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: fixed;
  bottom: 0;
  height: 4vh;
  font-family: Electrolize, sans-serif;

}

footer div {
  font-size: 0.8rem;
}

.lib {
    color: var(--text-warning);
    text-decoration: none;
    margin: 0 5px;
}

.link {
    color: var(--text-color);
    text-decoration: none;
    margin: 0 5px;
}