.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 0.5rem;
  padding: 0.5rem;
  min-width: 15vw;
  /* max-height: 80vh; */
  border-radius: 11px;
  background: linear-gradient(
    236deg,
    var(--background-light),
    var(--background-dark)
  );
  box-shadow: 20px 20px 60px var(--shadow-light),
    -20px -20px 60px var(--shadow-dark);
  /* 
  background: linear-gradient(
    -45deg,
    rgba(0, 0, 0, 0.12),
    rgba(255, 255, 255, 0.15)
  );
  box-shadow: 12px 12px 16px 0 rgba(0, 0, 0, 0.15),
    -8px -8px 12px 0 rgba(255, 255, 255, 0.2); */
  /* width: auto; */
}

.cardDark {
  background: linear-gradient(
    236deg,
    var(--background-light),
    var(--background-dark)
  );
  box-shadow: 20px 20px 60px var(--shadow-light),
    -20px -20px 60px var(--shadow-dark);
}
