.details::-webkit-scrollbar {
  display: none; /* For Chrome, Safari and Opera */
}

.details__container::-webkit-scrollbar {
  display: none; /* For Chrome, Safari and Opera */
}

.details__container {
  height: 100%;
  max-height: 90vh;
  overflow-y: auto;
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */

  display: flex;
  flex-direction: column;
  width: 100%;
}

.details__container p {
  max-height: 300px;
  font-family: Electrolize;
  font-size: 0.82rem;
  font-weight: 300;
  margin: 2px;
  padding: 0;
}

@keyframes softBlink {
  0%,
  100% {
    color: var(--text-alarmed);
  }
  50% {
    color: var(--text-warning);
  }
}

.soft-blinking-element {
  animation: softBlink 2s infinite ease-in-out;
}

.error {
  color: var(--text-alarmed);
  animation: softBlink 2.5s infinite ease-in-out;
}

.warn {
  color: var(--text-warning);
}

.info {
  color: var(--text-info);
}
