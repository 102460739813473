.case {
  width: 200px;
  height: 200px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.knob {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  /* background: linear-gradient(145deg, #1b3637, #172d2e);
  box-shadow: 7px 7px 14px #132727, -7px -7px 14px #1f3e3f; */
  background: linear-gradient(
    315deg,
    var(--background-dark),
    var(--background-light)
  );
  box-shadow: 7px 7px 20px var(--shadow-light),
    -7px -7px 20px var(--shadow-dark);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.input {
  border-radius: 50%;
  /* background: linear-gradient(145deg, #1b3637, #172d2e);
  box-shadow: 7px 7px 9px #132727, -7px -7px 9px #1f3e3f; */

  background: linear-gradient(
    315deg,
    var(--background-dark),
    var(--background-light)
  );
  box-shadow: 7px 7px 20px var(--shadow-light),
    -7px -7px 20px var(--shadow-dark);
}

.mark {
  position: absolute;
  width: 1px;
  height: 10px;
  background: var(--text-color);
  z-index: 2;
}

.selected {
  background: var(--text-alarmed);
}

.indicator {
  position: absolute;
  left: 50%;
  width: 1px;
  height: 10px;
  background: var(--text-color);
  z-index: 2;
  transform: translateY(-60px);
}

.indicator-text {
  font-family: Electrolize;
  font-size: 1rem;
  font-weight: 200;
}
