.gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  min-width: 50%;
  gap: 4px;
  flex-basis: fit-content;
}

.gallery:nth-of-type(2) {
  /* margin-left: 20px; */
}

