main {
  display: flex;
  justify-content: space-around;
  margin-bottom: 4vh;
}

main > section {
  width: 100%;
}

aside {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.logs {
  @media screen and (max-width: 1024px) {
    visibility: hidden;
    opacity: 0;
    width: 0;
  }
  visibility: visible;
  opacity: 1;
  transition: all 0.5s ease-in-out;
}
