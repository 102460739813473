.ledBox {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: auto;
  height: 20px;
  user-select: none;
}

.led {
  width: 5px;
  height: 5px;
  margin: 5px;
  opacity: 0.8;
}

.high {
  background-color: var(--text-alarmed);
  border-radius: 50%;
  box-shadow: 0 0 5px var(--text-alarmed);
}

.highDark {
  background-color: var(--text-info);
  border-radius: 50%;
  box-shadow: 0 0 5px var(--text-info);
}

.medium {
  background-color: var(--text-warning);
  box-shadow: 0 0 5px var(--text-warning);
  border-radius: 50%;
}

.mediumDark {
  background-color: var(--text-warning);
  box-shadow: 0 0 5px var(--text-warning);
  border-radius: 50%;
}

.low {
  background-color: rgba(201, 17, 17, 1);
  border-radius: 50%;
  box-shadow: 0 0 5px rgba(201, 17, 17, 1);
}

.lowDark {
  background-color: rgba(201, 17, 17, 1);
  border-radius: 50%;
  box-shadow: 0 0 5px rgba(201, 17, 17, 1);
}

.off {
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 50%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.8);
}
