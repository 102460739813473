.dragdrop {
  border: dashed 2px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  text-align: center;
  padding-top: 2rem;
  padding-bottom: 2rem;
  width: 100%;
  user-select: none;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}

.dragdropDark {
  border: dashed 2px rgba(255, 255, 255, 0.2);
}

p {
  font-family: Electrolize;
  margin: 0;
}
